export const preventDefault = (e) => e.preventDefault();
export const no_avatar = '/img/no_avatar.svg';

export const getUploadsURL = (img) => {
  if (img?.startsWith('http') || img?.startsWith('https')) {
    return img;
  }

  // console.log(import.meta.env);
  if (import.meta.env.DEV) {
    if (img?.indexOf('/uploads/') === 0) {
      return `http://localhost:8000${img}`;
    }
    return `http://localhost:8000/uploads/${img}`;
  }
  // const origin = window.location.hostname.split('.')[2];

  // if (origin === 'com')
  //   return `https://ua-vean.fra1.digitaloceanspaces.com/media/${img}`;
  return `/uploads/${img}`;
  // if (origin === 'de') return `https://de-vean.fra1.digitaloceanspaces.com/media/${img}`;
  // else if (origin === 'pl') return `https://pl-vean.fra1.digitaloceanspaces.com/media/${img}`;
  // return `https://ua-vean.fra1.digitaloceanspaces.com/media/${img}`;
};

// export const getUploadsURL = img => img ? `/uploads/${img}` : null
export const getAvatarURL = (img) => getUploadsURL(img);
export const exclude = (arr1, arr2) =>
  arr1.filter((o1) => arr2.map((o2) => o2.id).indexOf(o1.id) === -1);

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export { handleAxiosError, handleAxiosErrorSwal } from './handle.helpers';
export {
  isValidPhoneNumber,
  getCertificateValidity,
  joinUserNames,
  canManageSession,
  mapParlors,
  mapSessionTypes,
  isRole,
  isRoleGreat,
  isStore,
  toBase64,
  isRoleStore,
  toDataUrl,
  CanvaBuilt,
  mapChooser,
  base64ToBlob,
} from './func.helpers';
export {
  getStatusName,
  getCertificateType,
  getAdvertPaymentType,
  getRoleByName,
} from './switch.helpers';
export {
  SessionStatus,
  CertificatePaymentType,
  CertificateType,
  OrderStatus,
  Role,
  PriceStatic,
  PostCallStatuses,
  LATE_NOTE,
  ROLES,
  ROLES_PRIORATES,
  NO_TYPICAL_SITUATION,
  ROLES_FOR_DIRECTOR,
  STUDENT_STATUS,
  CUSTOMER_CART,
  MONTH_CHOICES,
  SELLER_STATUS,
  CHECK_BOX_SB,
  WORK_APPROVED,
  NEWS_TYPE,
  LESSON_TYPES,
  MODER_ALTERNATIVE_STATUS,
} from './enum.helpers.jsx';
export { months, years, PRICES } from './const.helpers';
export { Toast } from './toast.js';

export const selectStyles = (isInvalid) => {
  return {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#09715a' : '#3c9883',
      borderColor: isInvalid ? 'var(--warning)' : 'var(--primary)',
      borderRadius: '0.4285rem',
      fontSize: '0.75rem',
      transition:
        'color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
      '&:hover': {
        borderColor: state.isFocused ? '#efbc55' : null,
        boxShadow: 'none',
        backgroundColor: '#45a790',
      },
      '&:not(:focus)': {
        boxShadow: 'none',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'rgba(0,0,0,1)',
    }),
    menu: (provided) => ({
      ...provided,
      color: '#555',
      zIndex: '99999',
    }),
    container: (provided) => ({
      ...provided,
      minWidth: null,
      width: '100%',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'black',
    }),
    multiValue: (provided) => ({
      ...provided,
      justifyContent: 'space-between',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'red',
    }),
    valueContainer: (provided, state) =>
      state.isMulti
        ? {
            ...provided,
            textOverflow: 'ellipsis',
            maxWidth: '90%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'initial',
          }
        : provided,
    option: (provided, props) => {
      return {
        ...provided,
        color: props.data?.color,
        backgroundColor:
          props.selectProps?.getBackgroundColor && props.selectProps.getBackgroundColor(props.data),
      };
    },
  };
};
